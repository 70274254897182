import React from "react";
import styled, { keyframes } from "styled-components";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 2px solid var(--color-primary);
  border-right: 2px solid var(--color-primary);
  border-bottom: 2px solid var(--color-primary);
  border-left: 2px solid var(--color-primary);
  border-style: inset;
  background: transparent;
  width: calc(
    var(--text-base-size) * ${(props) => (props.size ? props.size : 1)}
  );
  height: calc(
    var(--text-base-size) * ${(props) => (props.size ? props.size : 1)}
  );
  border-radius: 50%;
  margin: auto;
`;

export default function ActivityIndicator(props) {
  return <Spinner size={props.size} {...props} />;
}
