import React, { useContext, useEffect, useState, useRef } from "react";
import { authFetch, ACCESS_TOKEN_KEY, setAuth } from "../../actions/auth";
import { Context } from "../../Store";
import { makeStyles } from "@mui/styles";
import { FixedSizeList as List } from "react-window";
import TextField from "@mui/material/TextField";
import AutoSizer from "react-virtualized-auto-sizer";
import clsx from "clsx";

import PropsSlamModal from "./PropsSlamModal";
import CustomLineModal from "./CustomLineModal";
import PlayerPropHeaders from "./PlayerPropHeaders";
import FetchingPlayerProps from "./FetchingPlayerProps";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

let SEARCHTERM = "";
export default function NewPlayerPropsScreen() {
  const [context, dispatch] = useContext(Context);
  const [playerProps, setPlayerProps] = useState([]);
  const [filteredProps, setFilteredProps] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isMiniLoading, setMiniLoading] = useState(true);
  const [apiBooks, setBooks] = useState([]);
  const [games, setGames] = useState({});
  const [allTypes, setAllTypes] = useState([]);
  const [typeFilter, setTypeFilter] = React.useState([]);
  const [sortByHold, setSortByHold] = React.useState(false);
  const [slamModalProps, setSlamModalProps] = useState({});
  const [slamModalOpen, setSlamModalOpen] = useState(false);
  const [customLineModalOpen, setCustomLineModalOpen] = useState(false);
  const [customLineOdd, setCustomLineOdd] = useState({});
  const [slamModalPropID, setSlamModalPropID] = useState("");
  const [error, setError] = useState("");
  const { league, timedelta, skipcache } = context;
  const {
    config: { api },
  } = context;
  const activeBooks = JSON.parse(window.localStorage.getItem("activeBooks"));
  const styles = useStyles();

  const fetchData =
    ({ shouldUpdateLoading = false }) =>
      () => {
        if (shouldUpdateLoading) {
          setLoading(true);
        }
        setMiniLoading(true);
        var booksApi = "";
        if (activeBooks.length > 0)
          booksApi = activeBooks.map((book) => book.content);
        authFetch(
          `${api}/stats/screen/player_prop_new/?league=${league}&timedelta=${timedelta}&books=${booksApi}&skipCache=${skipcache}`,
          {
            method: "GET",
          },
          [context],
        )
          .then((resp) => {
            if (!resp.ok) {
              if (resp.status === 403) {
                dispatch(setAuth({ isAuthenticated: false }));
                window.localStorage.removeItem(ACCESS_TOKEN_KEY);
                window.location.href = "/";
              }
              //refreshToken([context, dispatch]);
            }
            return resp.json();
          })
          .then((resp) => {
            const { props, books, games } = resp;
            if (!props || !books || !games) {
              //refreshToken([context, dispatch]);
              return;
            }

            const allTypesSorted = getTypesFromPlayerProps(props);

            setAllTypes(allTypesSorted);
            setPlayerProps(props);
            setFilteredProps(
              filterPlayerProps(props, SEARCHTERM, typeFilter, sortByHold, games),
            );

            function getKeyByValue(object, value) {
              return Object.keys(object).find((key) => object[key] === value);
            }

            function toObject(bookArr, bookObj) {
              var objArr = [];
              for (var i = 0; i < bookArr.length; ++i) {
                var bookID = getKeyByValue(bookObj, bookArr[i].content);
                var objToPush = {};
                objToPush[bookID] = bookArr[i].content;
                objArr.push(objToPush);
              }
              return objArr;
            }
            let booksToSet = toObject(
              [{ content: "True Line", id: "29" }].concat(activeBooks),
              books,
            );

            setBooks(booksToSet);
            setGames(games);
            setLoading(false);
            setMiniLoading(false);
          })
          .catch((e) => {
            //refreshToken([context, dispatch]);
            setLoading(false);
            setMiniLoading(false);
            console.log(e);
            setError(e.message);
          });
      };

  const RowComp = (props) => {
    const prop = props.prop;
    const index = props.index;
    const over = props.over;
    const onSlam = props.onSlam;
    const setCustomLine = props.setCustomLine;
    const lightBlue = "#8edff6";
    const darkBlue = "#20bde9";
    const lightGreen = "#A6f3bc";
    const darkGreen = "#2dd021";
    const yellow = "#FDDA0D";
    const pink = "#F8C8DC";
    const propColorBestEdge = yellow;
    const propColorEdge = over ? darkBlue : darkGreen;
    const propColorEdgeAdj = over ? lightBlue : lightGreen;
    const propColorBest = pink;
    const game = prop && prop.game ? games[prop.game] : null;

    if (apiBooks.length === 0) {
      return <></>;
    }

    return (
      <div
        className={clsx(
          styles.rowBlock,
          index % 2 === 1 && styles.lightGreyShade,
          index % 2 === 0 && styles.otherShade,
          over ? styles.overProp : styles.underProp,
        )}
      >
        <div className={styles.columnBlock} style={{ minWidth: "25px" }}>
          <span className={styles.columnItem}>{game ? game.date : ""}</span>
        </div>
        <div className={styles.columnBlock} style={{ minWidth: "25px" }}>
          <span className={styles.columnItem}>{game ? game.home : ""}</span>
        </div>
        <div className={styles.columnBlock} style={{ minWidth: "25px" }}>
          <span className={styles.columnItem}>{game ? game.away : ""}</span>
        </div>
        <div className={styles.columnBlock} style={{ minWidth: "120px" }}>
          {prop.player && (
            <span className={styles.columnItem}>{prop.player}</span>
          )}
          {prop.type_name === "moneyline" && (
            <span className={styles.columnItem}>
              {over ? (game ? game.away : "") : game ? game.home : ""}
            </span>
          )}
          {prop.type_name === "spread" && (
            <span className={styles.columnItem}>
              {over ? (game ? game.away : "") : game ? game.home : ""}
            </span>
          )}
          {prop.type_name === "total" && (
            <span className={styles.columnItem}>{over ? "Over" : "Under"}</span>
          )}
        </div>
        <div className={styles.columnBlock} style={{ minWidth: "75px" }}>
          <span className={styles.columnItem}>{prop.prop_type}</span>
        </div>
        <div
          className={clsx(
            styles.columnBlock,
            styles.numberBorder,
            prop.hold <= 0.5 && styles.lightGreenShade,
          )}
          style={{ minWidth: "40px" }}
        >
          <span className={styles.columnItem}>{prop.hold}</span>
        </div>
        {apiBooks.map((book, index) => {
          const bookName = Object.values(book)[0];
          const bookKey = Object.keys(book)[0];
          var odd = prop.odds.filter(function(o) {
            return o.book === bookKey;
          });
          if (odd.length > 0) {
            odd = odd[0];
          } else {
            odd = { number: "", id: "", over_odds: "", under_odds: "" };
          }
          const sharpEdge = over ? odd.sharp_over : odd.sharp_under;
          const sharpEdgeAdj = over ? odd.sharp_over_adj : odd.sharp_under_adj;
          return (
            <div
              prop_id={odd.id}
              className={clsx(
                styles.bookWrapper,
                odd.number && styles.slamIndicator,
                bookName === "True Line" && styles.trueLine,
              )}
              onClick={
                bookName === "True Line"
                  ? setCustomLine(odd)
                  : onSlam(odd, over, game, bookName)
              }
              title={
                sharpEdge
                  ? `Edge: ${sharpEdge}%\nEdge Adj: ${sharpEdgeAdj}%\n${Object.values(book)[0]
                  }\n${odd.created_at}`
                  : ""
              }
              style={{
                backgroundColor:
                  ((odd.min_u && !over) || (odd.min_o && over)) &&
                    ((over && sharpEdgeAdj > 0) || (!over && sharpEdge > 0))
                    ? propColorBestEdge
                    : sharpEdge > 0
                      ? propColorEdge
                      : sharpEdgeAdj > 0
                        ? propColorEdgeAdj
                        : (odd.min_u && !over) || (odd.min_o && over)
                          ? propColorBest
                          : "",
              }}
              key={odd.id + index}
            >
              <div
                prop_id={odd.id}
                className={clsx(styles.columnBlock, styles.numberBorder)}
                style={{ minWidth: "35px" }}
              >
                <span
                  prop_id={odd.id}
                  className={clsx(styles.columnItem, styles.columnNumber)}
                >
                  {odd.number
                    ? prop.type_name === "spread" && over
                      ? odd.number * -1
                      : odd.number
                    : ""}
                </span>
              </div>
              <div
                prop_id={odd.id}
                className={clsx(styles.columnBlock, styles.oddsBorder)}
                style={{ minWidth: "35px" }}
              >
                <span
                  prop_id={odd.id}
                  className={clsx(
                    styles.columnItem,
                    styles.columnNumber,
                    over
                      ? odd.over_odds >= 0
                        ? styles.plusNumber
                        : styles.minusNumber
                      : odd.under_odds >= 0
                        ? styles.plusNumber
                        : styles.minusNumber,
                  )}
                >
                  {over ? odd.over_odds : odd.under_odds}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const PlayerPropRow =
    (store, styles, onSlam, setCustomLine) =>
      ({ index, style }) => {
        const props = store[index];

        return (
          <div style={style}>
            <RowComp
              prop={props}
              over={true}
              index={index}
              onSlam={onSlam}
              setCustomLine={setCustomLine}
            />
            <RowComp
              prop={props}
              over={false}
              index={index}
              onSlam={onSlam}
              setCustomLine={setCustomLine}
            />
          </div>
        );
      };

  useEffect(() => {
    document.addEventListener("keypress", handleKeyPress);
    fetchData({ shouldUpdateLoading: true })();
    const interval = setInterval(
      fetchData({ shouldUpdateLoading: false }),
      1 * 45 * 1000,
    );
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [timedelta, league, typeFilter, sortByHold, skipcache, error]);

  const onSlam = (odd, over, game, book) => () => {
    setSlamModalProps({
      away: game.away,
      home: game.home,
      overUnder: over ? "O" : "U",
      gameTime: game.date,
      playerName: odd.player_name,
      propType: odd.prop_type,
      propNumber: odd.number,
      odds: over ? odd.over_odds : odd.under_odds,
      shortBookName: book,
    });
    setSlamModalPropID(odd.id);
    setSlamModalOpen(true);
  };

  const setCustomLine = (odd) => () => {
    setCustomLineOdd({ ...odd, league: league });
    setCustomLineModalOpen(true);
  };

  return (
    <>
      <div className={styles.root}>
        <div style={{ width: "100%", height: "92vh" }}>
          {slamModalPropID && (
            <PropsSlamModal
              isModalOpen={slamModalOpen}
              propID={slamModalPropID}
              handleModalClose={() => {
                setSlamModalOpen(false);
                setSlamModalPropID("");
                setSlamModalProps({
                  away: "",
                  home: "",
                  overUnder: "",
                  playerName: "",
                  propType: "",
                  propNumber: "",
                  odds: "",
                  gameTime: "",
                  shortBookName: "",
                });
              }}
              away={slamModalProps.away}
              home={slamModalProps.home}
              overUnder={slamModalProps.overUnder}
              gameTime={slamModalProps.gameTime}
              odds={slamModalProps.odds}
              playerName={slamModalProps.playerName}
              propType={slamModalProps.propType}
              propNumber={slamModalProps.propNumber}
              shortBookName={slamModalProps.shortBookName}
            />
          )}
          {customLineModalOpen && (
            <CustomLineModal
              isModalOpen={customLineModalOpen}
              handleModalClose={() => {
                setCustomLineModalOpen(false);
                setCustomLineOdd({});
              }}
              odd={customLineOdd}
            />
          )}
          <PlayerPropHeaders
            styles={styles}
            books={apiBooks}
            allTypes={allTypes}
            setTypeFilter={setTypeFilter}
            typeFilter={typeFilter}
            sortByHold={sortByHold}
            setSortByHold={setSortByHold}
            isLoading={isMiniLoading}
          />
          {filteredProps.length ? (
            <>
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    ref={listRef}
                    height={height - 30}
                    itemCount={filteredProps.length}
                    itemSize={35}
                    width={width}
                    onItemsRendered={OnListItemsRendered}
                  >
                    {PlayerPropRow(
                      filteredProps,
                      styles,
                      onSlam,
                      setCustomLine,
                    )}
                  </List>
                )}
              </AutoSizer>
            </>
          ) : isLoading ? (
            <FetchingPlayerProps styles={styles} />
          ) : (
            <div style={{ display: "flex", marginTop: 30 }}>
              <span style={{ fontSize: "24px", margin: "auto" }}>
                No {league} props{" "}
                {SEARCHTERM && `with search term "${SEARCHTERM}"`}
              </span>
            </div>
          )}
        </div>
        <SearchBar
          onSearch={(searchTerm) => {
            SEARCHTERM = searchTerm;
            setFilteredProps(
              filterPlayerProps(
                playerProps,
                searchTerm,
                typeFilter,
                sortByHold,
                games,
              ),
            );
          }}
          styles={styles}
        />
      </div>
      <Snackbar
        open={error !== ""}
        autoHideDuration={6000}
        onClose={() => setError("")}
      >
        <Alert severity="error">We ran into an error</Alert>
      </Snackbar>
    </>
  );
}

const listRef = React.createRef();

let VIS_START_INDEX = 0;
let VIS_STOP_INDEX = 0;

const OnListItemsRendered = ({ visibleStartIndex, visibleStopIndex }) => {
  VIS_START_INDEX = visibleStartIndex;
  VIS_STOP_INDEX = visibleStopIndex;
};

const handleKeyPress = (event) => {
  if (listRef.current !== null) {
    if (event.shiftKey === true) {
      const { current } = listRef;
      switch (event.key) {
        case "G": {
          current.scrollToItem(current.props.itemCount, "end");
          return;
        }
        case "T": {
          current.scrollToItem(0, "start");
          return;
        }
        case "J": {
          current.scrollToItem(VIS_STOP_INDEX, "start");
          return;
        }
        case "K": {
          current.scrollToItem(VIS_START_INDEX, "end");
          return;
        }
        default: {
          return;
        }
      }
    }
  }
};

const useStyles = makeStyles((theme) => ({
  root: {},
  fetchWrap: {
    margin: "auto",
    paddingTop: "25px",
  },
  fetchWrapChild: {
    margin: "auto",
  },
  fetchWrapChildSpinner: {
    margin: "auto",
    padding: theme.spacing(3),
  },
  fetchSpanText: {
    display: "flex",
    margin: "auto",
  },
  columnBlock: {
    overflow: "hidden",
    fontSize: "10px",
    display: "inline-flex",
    padding: "0px",
    flex: 1,
  },
  headerBlock: {
    overflow: "hidden",
    fontSize: "12px",
    display: "inline-flex",
    flex: 1,
  },
  rowBlock: {
    overflow: "hidden",
    display: "flex",
  },
  headerRowBlock: {
    overflow: "hidden",
    display: "flex",
    height: "30px",
    fontWeight: "800",
    borderTop: "2px solid",
    borderBottom: "1px solid",
    //maxWidth: `calc(100% - 16px)`,
  },
  overProp: {
    borderTop: "2px solid #000",
  },
  underProp: {
    borderTop: "1px dotted grey",
  },
  overUnder: {
    borderRight: "0.25px dotted grey",
  },
  columnItem: {
    margin: "0 auto",
    textAlign: "center",
    alignSelf: "center",
    justifyContent: "center",
    fontSize: "10px",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  columnItemBtn: {
    margin: "auto",
    fontWeight: "bold",
    textAlign: "center",
    alignSelf: "center",
    justifyContent: "center",
    fontSize: "10px",
  },
  columnNumber: {
    fontWeight: "600",
    fontSize: "12px",
  },
  plusNumber: {
    color: "#1a69e8",
  },
  minusNumber: {
    color: "#fa1e1e",
  },
  numberBorder: {
    borderLeft: "0.25px dotted grey",
  },
  oddsBorder: {
    borderRight: "0.25px dotted grey",
  },
  lightGreenShade: {
    backgroundColor: "#00800061",
  },
  otherShade: {
    backgroundColor: "#f2f2f2",
  },
  lightGreyShade: {
    backgroundColor: "#c2c0c0",
  },
  searchBarForm: {
    position: "absolute",
    bottom: "5px",
  },
  searchBarInput: {
    width: "300px",
    backgroundColor: "#FFF",
  },
  bookWrapper: {
    display: "inline-flex",
    flex: "2",
  },
  slamIndicator: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.light,
    },
  },
  trueLine: {
    backgroundColor: "lightgoldenrodyellow",
    borderRight: "2px solid black",
  },
}));

const filterPlayerProps = (
  playerProps,
  searchTerm,
  typeFilter,
  sortByHold,
  games,
) => {
  let newProps = playerProps;
  if (searchTerm) {
    newProps = playerProps.filter((prop) => {
      const game = games[prop.game];
      const home = game ? game.home.toUpperCase() : "";
      const away = game ? game.away.toUpperCase() : "";
      const search = searchTerm.toUpperCase();
      return (
        prop.player.toUpperCase().includes(search) ||
        home.includes(search) ||
        away.includes(search)
      );
    });
  }
  if (sortByHold) {
    newProps.sort(function(a, b) {
      var keyA = a.hold;
      var keyB = b.hold;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
  }

  if (newProps.length) {
    if (!typeFilter || typeFilter.length === 0) {
      return newProps;
    }
    const newerProps = newProps.filter(({ prop_type }) =>
      typeFilter.includes(prop_type),
    );

    return newerProps;
  }

  return newProps;
};

const SearchBar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const isOpenCopy = useRef(false);
  const SEARCH_BIND = useRef(null);
  const ESC_BIND = useRef(null);

  const { styles, onSearch } = props;
  useEffect(() => {
    SEARCH_BIND.current = (event) => {
      if (event.key === "/" && !isOpenCopy.current) {
        isOpenCopy.current = !isOpenCopy.current;
        setIsOpen(isOpenCopy.current);
        setSearchTerm("");
      }
      if (event.key === "/" && isOpenCopy.current) {
        setSearchTerm("");
      }
    };
    ESC_BIND.current = (event) => {
      if (event.key === "Escape") {
        if (isOpenCopy.current) {
          isOpenCopy.current = !isOpenCopy.current;
          setIsOpen(isOpenCopy.current);
        }
        onSearch("");
      }
    };
    document.addEventListener("keypress", SEARCH_BIND.current);
    document.addEventListener("keydown", ESC_BIND.current);
  }, [onSearch]);
  useEffect(() => {
    return () => {
      document.removeEventListener("keypress", SEARCH_BIND.current);
      document.removeEventListener("keydown", ESC_BIND.current);
    };
  }, []);

  if (isOpen) {
    return (
      <form
        className={styles.searchBarForm}
        noValidate
        autoComplete="off"
        onSubmit={(event) => {
          event.preventDefault();
          const searchFilter = searchTerm.replace("/", "");
          onSearch(searchFilter);
        }}
      >
        <TextField
          className={styles.searchBarInput}
          id="outlined-basic"
          label="Search Players"
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          variant="outlined"
          autoFocus
        />
      </form>
    );
  }
  return null;
};

const getTypesFromPlayerProps = (playerProps) => {
  let allTypesArr = playerProps.map((row) => {
    return row.prop_type;
  });
  let uniqueBrands = allTypesArr.filter(
    (item, index, array) => array.indexOf(item) === index,
  );
  return uniqueBrands;
};
