import React, { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
// import AccountCircle from "@mui/icons-material/AccountCircle";
import ViewList from "@mui/icons-material/ViewList";
import SettingsIcon from "@mui/icons-material/Settings";
import ReceiptIcon from "@mui/icons-material/Receipt";
// import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { makeStyles, useTheme } from "@mui/styles";
import { Context } from "./Store";
import { setView } from "./actions/view";
// import User from "./User";
// import PlayerPropsScreen from "./components/PlayerProps/PlayerPropsScreen";
import NewPlayerPropsScreen from "./components/PlayerProps/NewPlayerPropsScreen";
import GoPlayerPropsScreen from "./components/PlayerProps/GoPlayerPropsScreen";
import BooksSettings from "./components/Settings/BooksSettings";
// import Settings from "./components/Settings/Settings";
// import Analysis from "./components/Analysis/Analysis";
import Transactions from "./components/Transactions/Transactions";
import LeagueSelect from "./components/Select/LeagueSelect";
// import OrderSelect from "./components/Select/OrderSelect";
import TimedeltaSelect from "./components/Select/TimedeltaSelect";
import SkipcacheSelect from "./components/Select/SkipcacheSelect";
import FilterSelect from "./components/Select/FilterSelect";
import { logout } from "./actions/auth";
import { AuthButton } from "components/AuthButton";

const backgroundColor = "#24d48c"; // botGreen
const FEATURES = [
  {
    view: "playerProps",
    text: "Props Screen",
    icon: <ViewList style={{ color: "var(--color-primary)" }} />,
  },
  {
    view: "settings",
    text: "Books Settings",
    icon: <SettingsIcon style={{ color: "var(--color-primary)" }} />,
  },
  {
    view: "goPlayerProps",
    text: "Go Props Screen",
    icon: <ViewList style={{ color: "var(--color-primary)" }} />,
  },
  //{
  //  view: "transactions",
  //  text: "Transactions",
  //  icon: <ReceiptIcon style={{ color: backgroundColor }} />,
  //},
  //{
  //  view: "analysis",
  // text: "Analysis",
  //  icon: <AutoGraphIcon style={{ color: "#24d48c" }} />,
  //},
  //{
  //  view: "oldPlayerProps",
  //  text: "Old Screen",
  //  icon: <AccountCircle />,
  //},
  //{
  //  view: "oldSettings",
  //  text: "Old Settings",
  //  icon: <SettingsIcon />,
  //},
];

const VIEWS = {
  playerProps: <NewPlayerPropsScreen />,
  goPlayerProps: <GoPlayerPropsScreen />,
  settings: <BooksSettings />,
  transactions: <Transactions />,
  // analysis: <Analysis />,

  // older stuff
  // oldPlayerProps: <PlayerPropsScreen />,
  // oldSettings: <Settings />,
};

const drawerWidth = 256;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 8,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    display: "none",
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignSelf: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1, 1),
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "hidden",
  },
}));

export default function MainPage() {
  const classes = useStyles();
  const theme = useTheme();
  const [state, dispatch] = useContext(Context);
  const [open, setOpen] = React.useState(false);
  const { currentView: view } = state;

  const onSetView = (view) => {
    dispatch(setView(view));
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="secondary"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <img
            alt="Stamp Screen"
            //src="/img/logos/bangrip-white.png"
            src="/img/logos/betstamp-word-white.svg"
            style={{ height: "64px" }}
          />
          {
            <span style={{ color: "white" }}>
              {view === "goPlayerProps"
                ? "Player Props"
                : view === "settings"
                  ? "Settings"
                  : ""}
            </span>
          }
          <div
            style={{
              justifyContent: "flex-end",
              display: "flex",
              flex: 1,
              marginLeft: 5,
              gap: "2rem",
            }}
          >
            {["playerProps", "oldPlayerProps", "goPlayerProps"].indexOf(view) >=
              0 && (
                <>
                  {/* <SkipcacheSelect /> */}
                  <LeagueSelect />
                  <TimedeltaSelect />
                </>
              )}
            {view === "transactions" && <FilterSelect />}
          </div>
          <div
            style={{
              marginLeft: "auto",
            }}
          >
            {/* <User /> */}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {FEATURES.map(({ text, icon, view }) => (
            <ListItem
              button
              key={view}
              onClick={() => {
                onSetView(view);
                setOpen(false);
              }}
            >
              <ListItemIcon color="red">{icon}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}

          <Divider />
          <br />
          <AuthButton
            containerStyle={{ padding: "8px" }}
            onPress={() =>
              dispatch(
                logout([state, dispatch], () => {
                  window.location.href = "/login";
                }),
              )
            }
          >
            Logout
          </AuthButton>
          <Divider />
          <br />
          <br />
          <h6>Color Legend</h6>

          <ul style={{ padding: "4px" }}>
            <li
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "6px",
              }}
            >
              <div
                style={{
                  width: "16px",
                  height: "16px",
                  backgroundColor: "#20bde9",
                }}
              />
              {/* blue*/}
              Over edge
            </li>
            <li
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "6px",
              }}
            >
              <div
                style={{
                  width: "16px",
                  height: "16px",
                  backgroundColor: "#F8C8DC",
                }}
              />
              <div style={{ wordWrap: "break-word", width: "100%" }}>
                {/* light red */}
                Best over line, not an edge
              </div>
            </li>
            <li
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "6px",
              }}
            >
              <div
                style={{
                  width: "16px",
                  height: "16px",
                  backgroundColor: "#FDDA0D",
                }}
              />
              {/* yellow */}
              Best under line and an edge
            </li>
            <li
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "6px",
              }}
            >
              <div
                style={{
                  width: "16px",
                  height: "16px",
                  backgroundColor: "#2dd021",
                }}
              />
              {/* dark green */}
              Under edge
            </li>
            <li
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "6px",
              }}
            >
              <div
                style={{
                  width: "16px",
                  height: "16px",
                  backgroundColor: "#A6f3bc",
                }}
              />
              {/* light green */}
              Almost an under edge
            </li>
          </ul>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {VIEWS[view]}
      </main>
    </div>
  );
}
