import React, { useContext, useState } from "react";
import { Context } from "../../Store";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import clsx from "clsx";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";

import {  authFetch } from "../../actions/auth";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modalPosition: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    display: "flex",
    flexDirection: "column",
    minWidth: "700px",
  },
}));

const getRandomNumberBetween = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export default function CustomLineModal(props) {
  const classes = useStyles();
  const [context, dispatch] = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [propLine, setPropLine] = useState("");
  const [propOdds, setPropOdds] = useState("");
  const [error, setError] = useState("");
  const { isModalOpen, handleModalClose, odd } = props;

  const {
    config: { api },
  } = context;

  let slamWaitPic = `img/giphy${getRandomNumberBetween(0, 6)}.gif`;
  let slamWaitMessage = "You got it, I'm working on it.";
  let body = null;
  if (loading) {
    body = (
      <div className={clsx(classes.paper, classes.modalPosition)}>
        <h2 id="simple-modal-title" style={{ margin: "auto", padding: "12px" }}>
          {slamWaitMessage}
        </h2>
        {/*<img alt={"get ready to slam"} src={slamWaitPic} />*/}
        <div>
          <LinearProgress style={{ height: "8px" }} color="primary" />
        </div>
      </div>
    );
  } else if (results) {
    body = (
      <div className={clsx(classes.paper, classes.modalPosition)}>
        <h3
          id="simple-modal-title"
          style={{ margin: "auto", display: "flex", padding: "12px" }}
        >
          Under {propLine} {propOdds > 0 ? "+" + propOdds : propOdds}{" "}
          {odd.prop_type} is equivalent to:
        </h3>
        <br />
        <div style={{ margin: "auto" }}>{results}</div>
      </div>
    );
  } else {
    body = (
      <div className={clsx(classes.paper, classes.modalPosition)}>
        <h2 id="simple-modal-title" style={{ margin: "auto", padding: "12px" }}>
          Input Custom Line
        </h2>
        <h6 id="simple-modal-description" style={{ padding: "18px" }}>
          Put the sportsbook line and odds in, and we will return what the
          equivalent pricing is (closest to +100)
        </h6>
        <div style={{ display: "flex" }}>
          <h6 style={{ flex: 1 }}>Number</h6>
          <h6 style={{ flex: 1 }}>Under Odds</h6>
        </div>
        <div style={{ display: "flex", paddingBottom: "18px" }}>
          <TextField
            inputProps={{ type: "number" }}
            value={propLine}
            className={classes.paper}
            onChange={({ target: { value } }) => setPropLine(value)}
          ></TextField>
          <TextField
            inputProps={{ type: "number" }}
            value={propOdds}
            className={classes.paper}
            onChange={({ target: { value } }) => setPropOdds(value)}
          ></TextField>
        </div>
        <div style={{ display: "flex" }}>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "#FFF", margin: "auto" }}
            onClick={() => {
              if (loading) return;

              setLoading(true);
              authFetch(
                `${api}/stats/custom_line/?number=${propLine}&odds=${propOdds}&league=${odd.league
                }&prop_type=${odd.prop_type.replaceAll("+", "%2B")}`,
                {
                  method: "GET",
                },
                [context]
              )
                .then((resp) => {
                  if (!resp.ok) {
                    throw new Error("non 200 response from API");
                  }
                  return resp.json();
                })
                .then((resp) => {
                  setResults(resp);
                  setLoading(false);
                })
                .catch((e) => {
                  //refreshToken([context, dispatch]);
                  console.log(e);
                  setError(e.message);
                  setLoading(false);
                });
            }}
          >
            Calculate
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <Snackbar open={error !== ""} autoHideDuration={6000}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </div>
  );
}
