import { SET_GLOBAL_LOADING } from "./actions/loading";
import { SET_DROPDOWN_NAVDRAWER_OPEN } from "./actions/navigation";
import {
  SET_AUTH,
  ACCESS_TOKEN_KEY,
  USER,
  //REFRESH_TOKEN_KEY,
  //KEEP_USER_TOKEN_REFRESHED,
  //CLEAR_TOKEN_REFRESH_LOOP,
} from "./actions/auth";

import { SET_VIEW } from "./actions/view";

import {
  SET_LEAGUE,
  SET_FILTER,
  SET_TIMEDELTA,
  SET_SKIPCACHE,
  SET_ORDER,
} from "./actions/select";

function Reducer(state, action) {
  switch (action.type) {
    case SET_GLOBAL_LOADING: {
      const { isLoading } = action;
      return {
        ...state,
        isLoading,
      };
    }
    case SET_AUTH: {
      const { token, user, isAuthenticated, refresh } = action;
      window.localStorage.setItem(ACCESS_TOKEN_KEY, token);
      const userID = user.id
      window.localStorage.setItem(USER, userID);
      //window.localStorage.setItem(REFRESH_TOKEN_KEY, refresh);
      return {
        ...state,
        auth: {
          ...state.auth,
          //refresh,
          token,
          userID,
          isAuthenticated,
        },
      };
    }
    case SET_VIEW: {
      const { view } = action;
      return {
        ...state,
        currentView: view,
      };
    }
    case SET_DROPDOWN_NAVDRAWER_OPEN: {
      const { isOpen } = action;
      return {
        ...state,
        isNavDrawerOpen: isOpen,
      };
    }
    //case KEEP_USER_TOKEN_REFRESHED: {
    //  const { interval } = action;
    //  return {
    //    ...state,
    //    tokenRefreshInterval: interval,
    //  };
    //}
    //case CLEAR_TOKEN_REFRESH_LOOP: {
    //  const { tokenRefreshInterval } = state;
    //  if (tokenRefreshInterval) {
    //    clearInterval(tokenRefreshInterval);
    //  }
    //  return {
    //    ...state,
    //    tokenRefreshInterval: null,
    //  };
    //}
    case SET_LEAGUE: {
      const { league } = action;
      return {
        ...state,
        league,
      };
    }
    case SET_FILTER: {
      const { filter } = action;
      return {
        ...state,
        filter,
      };
    }
    case SET_TIMEDELTA: {
      const { timedelta } = action;
      return {
        ...state,
        timedelta,
      };
    }
    case SET_SKIPCACHE: {
      const { skipcache } = action;
      return {
        ...state,
        skipcache,
      };
    }
    case SET_ORDER: {
      const { order } = action;
      return {
        ...state,
        order,
      };
    }
    default:
      return state;
  }
}

export default Reducer;
