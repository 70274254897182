import React, { useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {  authFetch } from "../../actions/auth";
import { Context } from "../../Store";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {},
  fetchWrap: {
    margin: "auto",
  },
  fetchWrapChild: {
    margin: "auto",
  },
  fetchWrapChildSpinner: {
    margin: "auto",
    padding: theme.spacing(3),
  },
  fetchSpanText: {
    display: "flex",
    margin: "auto",
  },
  rowStyle: {
    fontSize: "12px",
    padding: "5px",
    flex: 0,
    textAlign: "center",
    border: "1px solid",
    fontWeight: "bold",
  },
  headerBlock: {
    overflow: "hidden",
    fontSize: "12px",
    display: "inline-flex",
    padding: "5px",
  },
  rowBlock: {
    overflow: "hidden",
    display: "flex",
  },
  headerRowBlock: {
    overflow: "wrap",
    height: "50px",
    width: "100px",
    fontWeight: "800",
    border: "1px solid",
    textAlign: "center",
    padding: "5px",
  },
  overProp: {
    borderTop: "2px solid #000",
  },
  underProp: {
    borderTop: "1px dotted grey",
  },
  overUnder: {
    borderRight: "0.25px dotted grey",
  },
  columnItem: {},
  columnNumber: {
    fontWeight: "600",
    fontSize: "12px",
  },
  plusNumber: {
    color: "green",
  },
  minusNumber: {
    color: "red",
  },
  numberBorder: {
    borderLeft: "0.25px dotted grey",
  },
  oddsBorder: {
    borderRight: "0.25px dotted grey",
  },
  playerName: {
    fontSize: "12px",
  },
  lighGreenShade: {
    backgroundColor: "#00800061",
  },
  lighGreyShade: {
    backgroundColor: "#8080805c",
  },
  searchBarForm: {
    position: "absolute",
    bottom: "5px",
  },
  searchBarInput: {
    width: "300px",
    backgroundColor: "#FFF",
  },
  bookWraper: {
    display: "inline-flex",
    flex: "2",
  },
  slamIndicator: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const FetchingTransactions = ({ styles }) => {
  return (
    <div className={styles.fetchWrap}>
      <div className={styles.fetchSpanText}>
        <div className={styles.fetchWrapChild}>
          Bang Bang, Loading Transactions, Bang Bang.
        </div>
      </div>
      <div className={styles.fetchSpanText}>
        <div className={styles.fetchWrapChildSpinner}>
          <CircularProgress />
        </div>
      </div>
    </div>
  );
};

export default function PlayerPropsScreen() {
  const [context, dispatch] = useContext(Context);
  const [transactions, setTransactions] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const styles = useStyles();
  const { filter } = context;
  const {
    config: { api },
  } = context;

  const fetchData =
    ({ shouldUpdateLoading = false }) =>
    () => {
      if (shouldUpdateLoading) {
        setLoading(true);
      }
      let url = filter
        ? `${api}/users/transaction_summary/?t_filter=${filter}`
        : `${api}/users/transaction_summary/`;
      authFetch(url, { method: "GET" }, [context])
        .then((resp) => {
          //if (!resp.ok) {
          //  refreshToken([context, dispatch]);
          //}
          return resp.json();
        })
        .then((resp) => {
          setTransactions([resp]);
          setLoading(false);
        })
        //.catch((e) => {
        //  refreshToken([context, dispatch]);
        //});
    };

  useEffect(() => {
    fetchData({ shouldUpdateLoading: true })();
    // eslint-disable-next-line
  }, [filter]);
  async function downloadCSV() {
    setLoading(true);

    const startDate = document.getElementById("startDate").value;
    const endDate = document.getElementById("endDate").value;

    // Construct the URL to include start_date and end_date
    let url = `${api}/users/transaction_csv/`;

    const queryParams = [];
    if (filter) {
      queryParams.push(`t_filter=${filter}`);
    }
    if (startDate) {
      queryParams.push(`start_date=${startDate}`);
    }
    if (endDate) {
      queryParams.push(`end_date=${endDate}`);
    }

    if (queryParams.length) {
      url += `?${queryParams.join("&")}`;
    }

    try {
      const resp = await authFetch(url, { method: "GET" }, [context]);

      if (!resp.ok) {
        //refreshToken([context, dispatch]);
        throw new Error("Network response was not ok");
      }

      const data = await resp.json();
      setTransactions([data]);

      if (!data) return; 

      let csvContent = "data:text/csv;charset=utf-8,";
      const headers = [
        "Account",
        "Current Week",
        "Current Week Our Share",
        "Current Week Count",
        "Previous Week",
        "Previous Week Our Share",
        "Previous Week Count",
        "Lifetime",
        "Lifetime Our Share",
        "Lifetime Count",
        "Pending",
        "Pending Our Share",
        "Pending Count",
      ];
      csvContent += headers.join(",") + "\r\n";

      for (let [accountName, accountData] of Object.entries(transactions[0])) {
        const row = [
          accountName,
          accountData.result_since_monday,
          accountData.result_share_since_monday,
          accountData.count_since_monday,
          accountData.result_since_prev_monday,
          accountData.result_share_since_prev_monday,
          accountData.count_since_prev_monday,
          accountData.graded_result,
          accountData.graded_result_share,
          accountData.graded_count,
          accountData.pending,
          accountData.pending_share,
          accountData.pending_count,
        ].join(",");

        csvContent += row + "\n";
      }

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "transactions.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("There was a problem fetching the transactions:", error);
    }
  }

  return (
    <>
      <div className={styles.root}>
        <div
          style={{ display: "flex", justifyContent: "center", padding: "10px" }}
        >
          <label>
            Start Date:
            <input type="date" id="startDate" />
          </label>
          <label>
            End Date:
            <input type="date" id="endDate" />
          </label>
          <button onClick={downloadCSV}>Download CSV</button>
        </div>
        <div style={{ width: "100%" }}>
          {!isLoading && transactions?.length ? (
            <>
              <div style={{ display: "flex" }}>
                <span style={{ margin: "auto" }}>
                  <table>
                    <HeaderRows styles={styles} />
                    {Object.keys(transactions[0]).map((item) => (
                      <tr key={item.id}>
                        <td className={styles.rowStyle}>{item}</td>
                        {Object.values(transactions[0][item]).map((val) => (
                          <td className={styles.rowStyle}>
                            <span
                              style={{
                                color:
                                  val < 0 ? "red" : val > 0 ? "green" : "black",
                              }}
                            >
                              {roundVal(val)}
                            </span>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </table>
                </span>
              </div>
            </>
          ) : isLoading ? (
            <FetchingTransactions styles={styles} />
          ) : (
            <div style={{ display: "flex" }}>
              <span style={{ fontSize: "24px", margin: "auto" }}>
                No Transactions right now...
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const roundVal = (number) => {
  // return number rounded to 0 decimal places if integer, else to 1
  if (!number) return number;
  return parseInt(number) === parseFloat(number)
    ? parseInt(number).toFixed(0)
    : (parseFloat(number) * 100) % 10 === 0
    ? parseFloat(number).toFixed(1)
    : (parseFloat(number) * 100) % 25 === 0
    ? parseFloat(number).toFixed(2)
    : parseFloat(number).toFixed(1);
};

const HeaderRows = ({ styles }) => {
  return (
    <tr className={styles.headerRowBlock}>
      <td className={styles.headerRowBlock}>Account</td>
      <td className={styles.headerRowBlock}>Current Week</td>
      <td className={styles.headerRowBlock}>Current Week Our Share</td>
      <td className={styles.headerRowBlock}>Current Week Count</td>
      <td className={styles.headerRowBlock}>Previous Week</td>
      <td className={styles.headerRowBlock}>Previous Week Our Share</td>
      <td className={styles.headerRowBlock}>Previous Week Count</td>
      <td className={styles.headerRowBlock}>Lifetime</td>
      <td className={styles.headerRowBlock}>Lifetime Our Share</td>
      <td className={styles.headerRowBlock}>Lifetime Count</td>
      <td className={styles.headerRowBlock}>Pending</td>
      <td className={styles.headerRowBlock}>Pending Our Share</td>
      <td className={styles.headerRowBlock}>Pending Count</td>
    </tr>
  );
};
