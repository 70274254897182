import React, { useEffect, useState } from "react";
import styled from "styled-components";

const RowDefault = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  ${(props) => {
    if (props.desktop) {
      return "@media only screen and (max-width: 625px) {display: none;}";
    }
    if (props.mobile) {
      return "@media only screen and (min-width: 625px) {display: none;}";
    }
  }}
`;

const ColDefault = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: center;
  ${(props) => {
    if (props.desktop) {
      return "@media only screen and (max-width: 625px) {display: none;}";
    }
    if (props.mobile) {
      return "@media only screen and (min-width: 625px) {display: none;}";
    }
  }}
`;

const DesktopLayoutStyled = styled.span`
  display: block;
  @media only screen and (max-width: 625px) {
    display: none;
  }
`;

const PhoneLayoutStyled = styled.span`
  display: none;
  @media only screen and (max-width: 625px) {
    display: block;
  }
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  grid-gap: 1rem;
`;

const StyledHorizontalScroller = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  alignitems: flex-start;
  width: 100vw;
  overflow-x: scroll;
`;

export function HorizontalScroller(props) {
  return <StyledHorizontalScroller {...props} />;
}

// https://www.pluralsight.com/guides/re-render-react-component-on-window-resize
// listen for window resizes, wait some ms, then call the function
// if another resize event comes in in the meantime, cancel the old when and reset the timer
// this allows us to render nothing on phones instead of just hiding it
function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export function DesktopLayout(props) {
  const [isPhone, setIsPhone] = useState(
    window.matchMedia("(max-width: 625px)").matches
  );
  useEffect(() => {
    // listen for resize events and handle them every 333ms
    const debouncedHandleResize = debounce(function handleResize() {
      setIsPhone(window.matchMedia("(max-width: 625px)").matches);
    }, 333);
    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  if (!isPhone) {
    return <DesktopLayoutStyled {...props} />;
  } else return <></>;
}

export function PhoneLayout(props) {
  const [isPhone, setIsPhone] = useState(
    window.matchMedia("(max-width: 625px)").matches
  );
  // listen for resize events and handle them every 333ms
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setIsPhone(window.matchMedia("(max-width: 625px)").matches);
    }, 333);
    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  if (isPhone) {
    return <PhoneLayoutStyled {...props} />;
  } else return <></>;
}

export function Row(props) {
  return (
    <RowDefault
      {...props}
      style={
        props.reverse
          ? { flexDirection: "row-reverse", ...props.style }
          : { flexDirection: "row", ...props.style }
      }
    />
  );
}

export function Col(props) {
  return (
    <ColDefault
      {...props}
      style={
        props.reverse
          ? { flexDirection: "column-reverse", ...props.style }
          : { flexDirection: "column", ...props.style }
      }
    />
  );
}

export function Grid(props) {
  return <StyledGrid {...props} />;
}

const StyledToolbar = styled.div`
  flex: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: var(--color-fg);
  padding: var(--space-xs) 0;
  box-sizing: border-box;
  min-height: 58px;
`;

export function Toolbar(props) {
  return <StyledToolbar {...props} />;
}

const StyledMainAreaWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  overflow-y: hidden;
  align-items: flex-start;
`;

export function MainAreaWrapper(props) {
  return <StyledMainAreaWrapper {...props} />;
}

const StyledInnerMainArea = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: hidden;
  box-sizing: border-box;
  height: 100%;
`;

export function InnerMainArea(props) {
  return <StyledInnerMainArea {...props} />;
}
