import React, { useEffect, useState } from "react";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
    },
  },
};

export default function PlayerPropHeaders({
  styles,
  books,
  allTypes,
  setTypeFilter,
  typeFilter,
  sortByHold,
  setSortByHold,
  isLoading,
}) {
  const [typeList, setTypeList] = useState(typeFilter);

  // this is not good code
  useEffect(() => {
    setTypeList(typeFilter);
  }, [typeFilter]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value.includes("All")) setTypeList([]);
    else {
      var newList = [];
      for (var i = 0, l = value.length; i < l; i++) {
        if (!newList.includes(value[i])) {
          newList.push(value[i]);
        } else {
          // delete it from newList
          const index = newList.indexOf(value[i]);
          newList.split(index, 1);
        }
      }
      setTypeList(newList);
    }
  };
  const onSubmit = () => {
    setTypeFilter(typeList);
  };
  if (!allTypes) return <div></div>;
  return (
    <div className={styles.headerRowBlock}>
      <div className={styles.headerBlock} style={{ minWidth: "25px" }}>
        <span className={styles.columnItem}>Date</span>
      </div>
      <div className={styles.headerBlock} style={{ minWidth: "25px" }}>
        <span className={styles.columnItem}>H</span>
      </div>
      <div className={styles.headerBlock} style={{ minWidth: "25px" }}>
        <span className={styles.columnItem}>A</span>
      </div>
      <div className={styles.headerBlock} style={{ minWidth: "120px" }}>
        {isLoading && (
          <CircularProgress size={20} style={{ alignSelf: "center" }} />
        )}
        <button
          onClick={() => {
            setSortByHold(false);
          }}
          className={styles.columnItemBtn}
          title="Sort props by game / name"
        >
          Name
        </button>
      </div>
      <div className={styles.headerBlock} style={{ minWidth: "75px" }}>
        <Select
          id="simple-menu"
          value={typeList}
          onChange={handleChange}
          input={<OutlinedInput fullWidth={true} label="Type" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
          multiple
        >
          <button
            onClick={onSubmit}
            style={{
              alignItems: "center",
              display: "flex",
              flex: 1,
              margin: "auto",
              justifyContent: "center",
            }}
          >
            Confirm
          </button>
          <MenuItem key={"menuitem-ALL"} value={"All"}>
            <ListItemText style={{ textAlign: "center" }} primary={"All"} />
          </MenuItem>
          {(() => {
            let container = [];
            allTypes.forEach((element, index) => {
              container.push(
                <MenuItem key={"menuitem-" + index} value={element}>
                  <Checkbox checked={typeList.indexOf(element) > -1} />
                  <ListItemText primary={element} />
                </MenuItem>,
              );
            });
            return container;
          })()}
        </Select>
      </div>
      <div
        className={styles.headerBlock}
        style={{ minWidth: "70px", flex: "2" }}
      >
        <button
          onClick={() => setSortByHold(!sortByHold)}
          className={styles.columnItemBtn}
          title="Sort props by edge"
        >
          Best
        </button>
        {isLoading && (
          <CircularProgress size={15} style={{ alignSelf: "center" }} />
        )}
      </div>
      {books.map(function(key) {
        return (
          <div
            className={styles.headerBlock}
            style={{ minWidth: "70px", flex: "2" }}
            key={Object.keys(key)[0]}
          >
            <span className={styles.columnItemBtn}>
              {Object.values(key)[0]}
            </span>
          </div>
        );
      })}
    </div>
  );
}
