import "./App.css";
import { useReducer } from "react";
import { initialState, Context } from "./Store";
import Reducer from "./Reducer";
import MainLayout from "./MainLayout";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Login from "./components/Authentication/LoginPage";

if (!!!window.localStorage.getItem("settings")) {
  window.localStorage.setItem(
    "settings",
    JSON.stringify({
      books: {
        Pinnacle: true,
        PropBuilder: true,
        Circa: true,
        Caesars: true,
        FanDuel: true,
        BetRivers: true,
        Bovada: true,
        Buckeye: true,
        BetMGM: true,
        DraftKings: true,
        PointsBet: true,
        Metallic: true,
        Betway: true,
        Coolbet: true,
        "Sports Interaction": true,
        Heritage: true,
        bet365: true,
        PlayNow: true,
        Betfred: true,
        BetVictor: true,
        Betsafe: true,
        PowerPlay: true,
        WynnBet: true,
        "Proline+": true,
        // Rivalry: true,
        Betano: true,
        "theScore Bet": true,
        BetOnline: true,
        "888Sport": true,
        Bookmaker: true,
        Bet99: true,
        Ace: true,
        Eagle: true,
        Unibet: true,
        NorthStar: true,
        bwin: true,
        Borgata: true,
        Sportingbet: true,
        PartyPoker: true,
        "ESPN Bet": true,
        Bodog: true,
        Fanatics: true,
        ParlayPlay: true,
        "Jock MKT": true,
        //"True Line": true,
      },
    })
  );
}

const ACTIVE_BOOKS = [
  //{ content: "True Line", id: "29" },
  { content: "Pinnacle", id: "1" },
  { content: "DraftKings", id: "11" },
  { content: "FanDuel", id: "5" },
  { content: "BetMGM", id: "10" },
  { content: "bet365", id: "18" },
  { content: "Caesars", id: "4" },
  { content: "BetRivers", id: "6" },
  { content: "PointsBet", id: "12" },
  { content: "Betway", id: "14" },
  { content: "Betano", id: "27" },
  { content: "Bet99", id: "33" },
  { content: "888Sport", id: "32" },
  { content: "theScore Bet", id: "28" },
  { content: "Proline+", id: "25" },
];
const INACTIVE_BOOKS = [
  { content: "Sports Interaction", id: "16" },
  { content: "ESPN Bet", id: "42" },
  { content: "Circa", id: "3" },
  { content: "BetVictor", id: "21" },
  { content: "PowerPlay", id: "23" },
  { content: "WynnBet", id: "24" },
  { content: "Coolbet", id: "15" },
  { content: "BetFred", id: "20" },
  { content: "Betsafe", id: "22" },
  { content: "PlayNow", id: "19" },
  { content: "Rivalry", id: "26" },
  { content: "Unibet", id: "36" },
  { content: "NorthStar", id: "37" },
  { content: "bwin", id: "38" },
  { content: "Borgata", id: "39" },
  { content: "Sportingbet", id: "40" },
  { content: "PartyPoker", id: "41" },
  { content: "Fanatics", id: "44" },
  { content: "ParlayPlay", id: "45" },
  { content: "Jock MKT", id: "46" },
  { content: "Bookmaker", id: "31" },
  { content: "BetOnline", id: "29" },
  { content: "PropBuilder", id: "2" },
  { content: "Bodog", id: "43" },
  { content: "Bovada", id: "7" },
  { content: "Buckeye", id: "9" },
  { content: "Ace", id: "34" },
  { content: "Eagle", id: "35" },
  { content: "Metallic", id: "13" },
  { content: "Heritage", id: "17" },
];

if (!!!window.localStorage.getItem("activeBooks")) {
  window.localStorage.setItem("activeBooks", JSON.stringify(ACTIVE_BOOKS));
  window.localStorage.setItem("inactiveBooks", JSON.stringify(INACTIVE_BOOKS));
}

const theme = createTheme({
  palette: {
    primary: {
      //main: "#24d48c", // BotRed
      main: "#40a0db", // betstamp blue
    },
    secondary: {
      main: "#FFF", // white
    },
  },
});

// for route requiring login
const ProtectedRoute = (props) => {
  let userToken = window.localStorage.getItem("access");

  if (userToken) {
    return (
      <Route exact path={props.path}>
        {props.children}
      </Route>
    );
  } else {
    return <Redirect exact to="/login" />;
  }
};

function App() {
  const appContext = useReducer(Reducer, initialState);

  return (
    <ThemeProvider theme={theme}>
      <Context.Provider value={appContext}>
        <Router>
          <ProtectedRoute exact path="/">
            <MainLayout />
          </ProtectedRoute>
          <ProtectedRoute exact path="/screen">
            <MainLayout />
          </ProtectedRoute>
          <Route exact path="/login">
            <Login />
          </Route>
        </Router>
      </Context.Provider>
    </ThemeProvider>
  );
}

export default App;
