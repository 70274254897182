import React, { useMemo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// components
import ActivityIndicator from "./generic/ActivityIndicator";

const StyledBtn = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: var(--text-base-size);
  font-family: "Noto Sans", sans-serif;
  text-decoration: none;
  outline: none;
  transition: all var(--std-transition);

  cursor: ${(props) => {
    if (props.disabled) {
      return "not-allowed";
    } else {
      return "pointer";
    }
  }};

  &:hover {
    box-shadow: ${(props) => {
      if (!props.disabled) {
        return `inset 0 0 0 100vh rgba(255, 255, 255, 0.33);`;
      }
    }};
  }

  &:active {
    box-shadow: ${(props) => {
      if (!props.disabled) {
        return `inset 0 0 0 100vh rgba(0, 0, 0, 0.1);`;
      }
    }};
  }
`;

export function AuthButton(props) {
  const {
    leftIcon,
    leftIconColor,
    rightIcon,
    rightIconColor,
    disabled,
    isLoading,
    overrideChildren,
  } = props;

  const internalDisabled = disabled || isLoading;

  const colorTheme = useMemo(
    () => getColorTheme(props.colorTheme, disabled),
    [props.colorTheme, disabled]
  );

  const btnTheme = useMemo(() => getBtnTheme(props.btnTheme), [props.btnTheme]);

  const LeftIconComp = leftIcon || null;
  const RightIconComp = rightIcon || null;

  return (
    <div
      style={{
        width: "100%",
        flex: 1,
        margin: "var(--space-xs) 0",
        ...props.containerStyle,
      }}
    >
      <StyledBtn
        colorTheme={colorTheme}
        disabled={internalDisabled}
        style={{
          ...btnTheme.btn,
          ...colorTheme,
          ...props.btnStyle,
        }}
        onClick={() => props.onPress()}
      >
        {LeftIconComp && (
          <LeftIconComp
            color={leftIconColor || colorTheme.color}
            size={30}
            style={{ padding: 0, flex: "0 0 30px" }}
          />
        )}

        {isLoading ? (
          <ActivityIndicator size={1.4} />
        ) : (
          <>
            {overrideChildren ? (
              <>{props.children}</>
            ) : (
              <span
                style={{
                  flex: 1,
                  color: colorTheme.color,
                  fontWeight: "bold",
                  textDecoration: "none",
                  ...props.textStyle,
                }}
              >
                {props.children}
              </span>
            )}
          </>
        )}

        {RightIconComp && (
          <RightIconComp
            color={rightIconColor || colorTheme.color}
            size={30}
            style={{ padding: 0, flex: "0 0 30px" }}
          />
        )}
      </StyledBtn>
    </div>
  );
}

export function LinkButton(props) {
  return (
    <Link
      to={props.disabled ? "#" : props.to}
      style={{
        textDecoration: "none",
        whiteSpace: "nowrap",
        ...props.linkStyle,
      }}
    >
      <AuthButton {...props} onPress={() => {}} />
    </Link>
  );
}

const StyledIconBtn = styled.button`
  font-size: var(--text-base-size);
  font-family: "Noto Sans", sans-serif;
  text-decoration: none;
  outline: none;
  transition: all var(--std-transition);
  cursor: pointer;
  border: none;
  flex: 0;
  border-radius: 32px;
  border-width: 1px;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: center;
  &:hover {
    box-shadow: ${(props) => {
      if (!props.disabled) {
        return `inset 0 0 0 100vh rgba(255, 255, 255, 0.33);`;
      }
    }};
  }

  &:active {
    box-shadow: ${(props) => {
      if (!props.disabled) {
        return `inset 0 0 0 100vh rgba(0, 0, 0, 0.1);`;
      }
    }};
  }
`;

export function IconButton(props) {
  const { subtext, iconName, iconSize, iconColor, disabled, isLoading } = props;

  const internalDisabled = disabled || isLoading;

  const colorTheme = useMemo(
    () => getColorTheme(props.colorTheme, disabled),
    [props.colorTheme, disabled]
  );

  const IconComp = iconName;

  return (
    <div
      style={{
        ...props.containerStyle,
      }}
    >
      <StyledIconBtn
        style={{
          ...colorTheme,
          borderWidth: 0,
          backgroundColor: "transparent",
          ...props.btnStyle,
        }}
        onClick={
          internalDisabled
            ? null
            : () => {
                props.onPress();
              }
        }
        tabIndex={props.tabIndex}
        type="button"
      >
        {isLoading ? (
          <ActivityIndicator />
        ) : (
          <>
            <IconComp
              style={{ textAlign: "center" }}
              size={iconSize || 24}
              color={iconColor || colorTheme.color}
            />
            {subtext && (
              <small style={{ textAlign: "center", color: colorTheme.color }}>
                {subtext}
              </small>
            )}
          </>
        )}
      </StyledIconBtn>
    </div>
  );
}

function getColorTheme(theme, disabled) {
  const actualTheme = disabled ? "disabled" : theme || "primary";
  const THEMES = {
    primary: {
      backgroundColor: "transparent",
      color: "var(--color-primary)",
      borderColor: "var(--color-primary)",
    },
    danger: {
      backgroundColor: "transparent",
      color: "var(--color-danger)",
      borderColor: "var(--color-danger)",
    },
    inverted: {
      backgroundColor: "var(--color-primary)",
      color: "white",
      borderColor: "var(--color-primary)",
    },
    text: {
      backgroundColor: "transparent",
      color: "var(--color-text)",
      borderColors: "var(--color-text)",
    },
    disabled: {
      backgroundColor: "var(--color-text-light)",
      color: "var(--color-text-light)",
      borderColor: "var(--color-text-light)",
    },
    white: {
      backgroundColor: "white",
      color: "var(--color-primary)",
      borderColor: "white",
    },
    complement: {
      backgroundColor: "transparent",
      color: "var(--color-complement)",
      borderColor: "var(--color-complement)",
    },
  };
  return THEMES[actualTheme];
}

function getBtnTheme(theme) {
  const actualTheme = theme || "std";

  const std = {
    btn: {
      borderStyle: "solid",
      borderWidth: 2,
      borderRadius: 32,
      padding: "var(--space-xxs) var(--space-sm)",
    },
    // container: { marginVertical: 'var(--space-xs)', borderRadius: 32 },
  };

  const THEMES = {
    std: std,
    modalBottom: {
      btn: { padding: "var(--space-md)", borderWidth: 0, borderRadius: 0 },
    },
    borderless: {
      btn: { ...std.btn, borderWidth: 0 },
    },
    slim: {
      btn: {
        ...std.btn,
        paddingVertical: "var(--space-xxs)",
        marginVertical: "var(--space-xxxxs)",
      },
    },
  };
  return THEMES[actualTheme];
}
