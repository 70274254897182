import React, { useContext } from "react";
import { Context } from "../../Store";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { setFilter } from "../../actions/select";

const useStyles = makeStyles((theme) => ({
  filterSelect: {
    display: "flex",
    width: "280px",
    padding: "8px 0",
    marginTop: "8px",
    marginLeft: "auto",
  },
  formControlTouchUp: {
    backgroundColor: "#FFF",
  },
}));

export default function FilterSelect() {
  const [{ filter }, dispatch] = useContext(Context);
  const styles = useStyles();
  return (
    <FormControl variant="outlined" className={styles.filterSelect}>
      <InputLabel id="demo-simple-select-label" style={{ color: "#FFF" }}>
        Filter
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={filter}
        className={styles.formControlTouchUp}
        onChange={({ target: { value } }) => dispatch(setFilter(value))}
      >
        <MenuItem value="accounts">Account</MenuItem>
        <MenuItem value="league">League</MenuItem>
        <MenuItem value="prop_type">Prop Type</MenuItem>
      </Select>
    </FormControl>
  );
}
