import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
}));

// looking at react-beatufiul-dnd
// https://github.com/atlassian/react-beautiful-dnd
// copy code from https://codesandbox.io/s/-w5szl?file=/src/index.js:0-4999
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// Moves an item from one list to another list.
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: 250,
});

function DragBooks() {
  const activeBooks = JSON.parse(
    window.localStorage.getItem("activeBooks"),
  ).filter((b) => b.content !== "True Line");

  const inactiveBooks = JSON.parse(
    window.localStorage.getItem("inactiveBooks"),
  );

  const [state, setState] = useState([activeBooks, inactiveBooks]);

  function onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];
      newState[sInd] = items;
      setState(newState);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      const newState = [...state];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setState(newState.filter((group) => group.length));
    }
  }

  useEffect(() => {
    window.localStorage.setItem("activeBooks", JSON.stringify(state[0]));
    if (state[1]) {
      window.localStorage.setItem("inactiveBooks", JSON.stringify(state[1]));
    } else {
      window.localStorage.setItem("inactiveBooks", JSON.stringify([]));
    }
  }, [state]);
  return (
    <div>
      <div style={{ display: "flex" }}>
        <DragDropContext onDragEnd={onDragEnd}>
          {state.map((el, ind) => (
            <Droppable key={ind} droppableId={`${ind}`}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      marginBottom: 5,
                    }}
                  >
                    {ind === 0 ? "Active Books" : "Inactive Books"}
                  </p>
                  {el.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              fontWeight: "bold",
                            }}
                          >
                            {item.content}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </DragDropContext>
      </div>
    </div>
  );
}

export default function BooksSettings(props) {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <h6 style={{ textAlign: "center" }}>Drag and drop books to reorder</h6>
      <div>
        <DragBooks />
      </div>
    </div>
  );
}
