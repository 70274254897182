import { createContext } from "react";
import { setupInitalAuthContext } from "./actions/auth";

export const initialState = {
  auth: setupInitalAuthContext(),
  config: {
    api:
      process.env.NODE_ENV === "production"
        ? "https://bang.rip/backend"
        : "http://127.0.0.1:9000",
  },
  isLoading: false,
  isNavDrawerOpen: false,
  league: "NBA",
  timedelta: 24,
  skipcache: false,
  order: "game__date",
  filter: "accounts",
  date: new Date().valueOf(),
  currentView: "playerProps",
};
export const Context = createContext(initialState);
