import React, { useContext, useState } from "react";
import { Context } from "../../Store";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import clsx from "clsx";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";

import {  authFetch } from "../../actions/auth";
import JSONTree from "react-json-tree";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modalPosition: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    display: "flex",
    flexDirection: "column",
    minWidth: "1000px",
    maxHeight: "80%",
    overflowY: "auto",
  },
}));

const getRandomNumberBetween = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
export default function PropsSlamModal(props) {
  const classes = useStyles();
  const [context, dispatch] = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [lineHistory, setLineHistory] = useState(false);
  const [results, setResults] = useState(null);
  const [error, setError] = useState("");
  const {
    propID,
    isModalOpen,
    handleModalClose,
    playerName,
    overUnder,
    propNumber,
    odds,
    propType,
    shortBookName,
  } = props;

  const {
    config: { api },
    auth: {
      user: userID
    },
  } = context;

  let slamWaitPic = `img/giphy${getRandomNumberBetween(0, 6)}.gif`;
  let slamWaitMessage = "You got it, I'm working on it.";
  let body = null;
  if (loading) {
    body = (
      <div className={clsx(classes.paper, classes.modalPosition)}>
        <h2 id="simple-modal-title" style={{ margin: "auto", padding: "12px" }}>
          {slamWaitMessage}
        </h2>
        {/* <img alt={"get ready to slam"} src={slamWaitPic} />*/}
        <div>
          <LinearProgress style={{ height: "8px" }} color="primary" />
        </div>
      </div>
    );
  } else if (results) {
    body = (
      <div className={clsx(classes.paper, classes.modalPosition)}>
        <h2 id="simple-modal-title" style={{ margin: "auto", padding: "12px" }}>
          Slam Results
        </h2>
        <h6 style={{ margin: "auto", textDecoration: "underline" }}>
          {playerName} {propType} {overUnder} {propNumber}{" "}
          {shortBookName.toUpperCase()}
        </h6>
        {lineHistory ? (
          <div>
            <table style={{ margin: "auto", paddingTop: "10px" }}>
              {results.map((row) => (
                <tr>
                  {row.map((col) => (
                    <td
                      style={{
                        textAlign: "center",
                        padding: "2px 20px",
                        border: "2px solid rgb(0,0,0)",
                      }}
                    >
                      {col}
                    </td>
                  ))}
                </tr>
              ))}
            </table>
          </div>
        ) : (
          <div className={clsx(classes.paper, classes.modalPosition)}>
            <h2
              id="simple-modal-title"
              style={{ margin: "auto", padding: "12px" }}
            >
              Slam Results
            </h2>
            <h6 style={{ margin: "auto", textDecoration: "underline" }}>
              {playerName} {propType} {overUnder} {propNumber}{" "}
              {shortBookName.toUpperCase()}
            </h6>
            <div>
              <JSONTree data={results} />
            </div>
          </div>
        )}
      </div>
    );
  } else {
    const shortPropSlamString = `${playerName} ${overUnder === "U" ? "UNDER" : "OVER"
      } ${propNumber} ${propType} at ${odds} on ${shortBookName.toUpperCase()}`;
    body = (
      <div className={clsx(classes.paper, classes.modalPosition)}>
        <h6
          id="simple-modal-description"
          style={{ margin: "auto", padding: "18px" }}
        >
          Viewing bet of {shortPropSlamString}?
        </h6>
        <div style={{ display: "flex" }}>
          <Button
            variant="contained"
            color="secondary"
            style={{ margin: "auto", fontWeight: "bold" }}
            onClick={() => {
              if (loading) return;

              setLoading(true);
              authFetch(
                `${api}/stats/line_history/?prop_id=${propID}`,
                {
                  method: "GET",
                },
                [context]
              )
                .then((resp) => {
                  if (!resp.ok) {
                    throw new Error("non 200 response from API");
                  }
                  return resp.json();
                })
                .then((resp) => {
                  setLineHistory(true);
                  setResults(resp);
                  setLoading(false);
                })
                .catch((e) => {
                  //refreshToken([context, dispatch]);
                  setError(e.message);
                  setLoading(false);
                });
            }}
          >
            Show Line History
          </Button>
          {userID <= 15 && shortBookName.toUpperCase() === "PROPBUILDER" && (
            <Button
              variant="contained"
              color="primary"
              style={{ color: "#FFF", fontWeight: "bold", margin: "auto" }}
              onClick={() => {
                if (loading) return;

                setLoading(true);
                authFetch(
                  `${api}/users/slam_props/?prop_id=${propID}&outcome=${overUnder.toLowerCase()}`,
                  {
                    method: "GET",
                  },
                  [context]
                )
                  .then((resp) => {
                    if (!resp.ok) {
                      throw new Error("non 200 response from API");
                    }
                    return resp.json();
                  })
                  .then((resp) => {
                    setResults(resp);
                    setLoading(false);
                  })
                  .catch((e) => {
                    //refreshToken([context, dispatch]);
                    setError(e.message);
                    setLoading(false);
                  });
              }}
            >
              Place Bet
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <Snackbar open={error !== ""} autoHideDuration={6000}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </div>
  );
}
