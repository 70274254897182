import jwt_decode from "jwt-decode";
import { formatDateString } from "../utils/datetimes";
import { encode } from "base-64";

export const ACCESS_TOKEN_KEY = "access";
export const USER = "user";
//export const REFRESH_TOKEN_KEY = "refresh";
//export const REFRESH_TOKEN_INTERVAL = 50 * 60 * 1000;

//export const extractUserFromToken = function(token) {
//  if (!token || token === "undefined" || token === "null") {
//    window.localStorage.removeItem(ACCESS_TOKEN_KEY);
//    window.localStorage.removeItem(REFRESH_TOKEN_KEY);
//    return null;
//  }
//
//  const decodedUser = jwt_decode(token);
//  decodedUser.roles = [decodedUser.jti];
//  return decodedUser;
//};

export const isTokenValid = function(decodedToken) {
  if (!decodedToken) return false;
  const expiresAt = decodedToken["exp"] * 1000; // ms time from unix
  return new Date().valueOf() < expiresAt;
};

export const setupInitalAuthContext = function() {
  const token = window.localStorage.getItem(ACCESS_TOKEN_KEY);
  const user = window.localStorage.getItem(USER);
  if (token === null) {
    return {
      isAuthenticated: false,
      user: null,
      token: null,
    };
  }
  //const user = extractUserFromToken(token);
  return {
    token,
    isAuthenticated: true,
    //isAuthenticated: isTokenValid(user),
    user,
  };
};

export const login = function(values, callback, errorCallback, [context]) {
  const {
    config: { api: host },
  } = context;

  const options = {
    method: "POST",
    body: {},
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Basic " +
        encode(`${values?.username?.toLowerCase()}:${values.password}`),
    },
  };
  fetch(`${host}/auth/login/`, options)
    .then((response) => response.json())
    .then((result) => callback(result))
    .catch((error) => {
      errorCallback(error);
    });
};

//export const register = function(values, callback, errorCallback, [context]) {
//  const {
//    config: { api: host },
//  } = context;
//  const { firstName, lastName, password, username, captchaKey, gender, dob } =
//    values;
//
//  const options = {
//    method: "POST",
//    headers: new Headers({
//      Authorization: `Custom ${captchaKey}`,
//    }),
//    body: JSON.stringify({
//      firstName,
//      lastName,
//      password,
//      email: username,
//      gender,
//      dob: formatDateString(dob),
//    }),
//  };
//  fetch(`${host}/register`, options)
//    .then((response) => response.text())
//    .then((result) => callback(result))
//    .catch((error) => errorCallback(error));
//};

export const SET_AUTH = "SET_AUTH";
export const setAuth = ({ token, isAuthenticated, user, refresh }) => ({
  type: SET_AUTH,
  token: token || "",
  user: user || "",
  refresh: refresh || "",
  isAuthenticated,
});

//export const CLEAR_TOKEN_REFRESH_LOOP = "CLEAR_TOKEN_REFRESH_LOOP";
//export const clearTokenRefreshLoop = () => ({
//  type: CLEAR_TOKEN_REFRESH_LOOP,
//});

//export const KEEP_USER_TOKEN_REFRESHED = "KEEP_USER_TOKEN_REFRESHED";
//export const keepUserTokenRefreshed = ([context, dispatch]) => {
//  const interval = setInterval(refreshToken, REFRESH_TOKEN_INTERVAL);
//
//  return {
//    type: KEEP_USER_TOKEN_REFRESHED,
//    interval,
//  };
//};

//export const refreshToken = ([context, dispatch]) => {
//  const token = window.localStorage.getItem(REFRESH_TOKEN_KEY);
//  const {
//    config: { api: host },
//  } = context;
//  fetch(`${host}/api/token/refresh/`, {
//    method: "POST",
//    body: `refresh=${token}`,
//    headers: {
//      "Content-Type": "application/x-www-form-urlencoded",
//    },
//  })
//    .catch(() => {
//      dispatch(setAuth({ isAuthenticated: false }));
//      return;
//    })
//    .then((resp) => resp.json())
//    .catch(() => {
//      dispatch(setAuth({ isAuthenticated: false }));
//      return;
//    })
//    .then((resp) => {
//      const { access } = resp;
//      window.localStorage.setItem(ACCESS_TOKEN_KEY, access);
//    })
//    .catch(() => {
//      dispatch(setAuth({ isAuthenticated: false }));
//      return;
//    });
//};

export const logout = ([context, dispatch], successCallback) => {
  //window.localStorage.removeItem(REFRESH_TOKEN_KEY);
  //dispatch(clearTokenRefreshLoop());
  const {
    config: { api: host },
  } = context;

  const token = window.localStorage.getItem(ACCESS_TOKEN_KEY);
  const options = {
    method: "POST",
    body: {},
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  };
  fetch(`${host}/auth/logout/`, options)
    //.then((response) => response.json())
    .then((result) => {
      //console.log("logout result", result);
      dispatch(setAuth({ isAuthenticated: false }));
      window.localStorage.removeItem(ACCESS_TOKEN_KEY);
      successCallback();
    })
    .catch((error) => {
      console.log("error logging out", error);
    });
  return {
    type: null,
  };
};

export const authFetch = (path, options, [context]) => {
  const token = window.localStorage.getItem(ACCESS_TOKEN_KEY);
  const newOpts = {
    ...options,
    headers: new Headers({
      ...options.headers,
      Authorization: `Token ${token}`,
    }),
  };
  return fetch(path, newOpts);
};
