import React, { useContext } from "react";
import { Context } from "../../Store";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { setLeague } from "../../actions/select";
import TimedeltaSelect from "./TimedeltaSelect";

const useStyles = makeStyles((theme) => ({
  leagueSelect: {
    width: "100px",
    padding: "8px 0",
    marginTop: "8px",
  },
  formControlTouchUp: {
    backgroundColor: "#FFF",
    marginLeft: "10px",
    marginTop: "8px",
    height: 45,
  },
}));

function League({ league, selectedLeague }) {
  const [_, dispatch] = useContext(Context);
  return (
    <div
      style={{
        fontWeight: league === selectedLeague ? "bold" : "normal",
        textDecoration: league === selectedLeague ? "underline" : "none",
        cursor: "pointer",
      }}
      onClick={() => {
        dispatch(setLeague(league));
      }}
    >
      {league}
    </div>
  );
}

export default function LeagueSelect() {
  const [context, dispatch] = useContext(Context);
  const {
    league,
    auth: { user: userID },
  } = context;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        color: "white",
        gap: "1rem",
      }}
    >
      <League league="NFL" selectedLeague={league} />
      <League league="NBA" selectedLeague={league} />
      <League league="NHL" selectedLeague={league} />
      {userID <= 15 && (
        <>
          <League league="UFC" selectedLeague={league} />
          <League league="MLB" selectedLeague={league} />
          <League league="NCAAF" selectedLeague={league} />
          <League league="NCAAB" selectedLeague={league} />
        </>
      )}
    </div>
  );
}
