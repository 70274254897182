export const SET_LEAGUE = "SET_LEAGUE";
export const setLeague = (league) => ({
  type: SET_LEAGUE,
  league,
});

export const SET_FILTER = "SET_FILTER";
export const setFilter = (filter) => ({
  type: SET_FILTER,
  filter,
});

export const SET_TIMEDELTA = "SET_TIMEDELTA";
export const setTimedelta = (timedelta) => ({
  type: SET_TIMEDELTA,
  timedelta,
});

export const SET_SKIPCACHE = "SET_SKIPCACHE";
export const setSkipcache = (skipcache) => ({
  type: SET_SKIPCACHE,
  skipcache,
});

export const SET_ORDER = "SET_ORDER";
export const setOrder = (order) => ({
  type: SET_ORDER,
  order,
});
