import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import LoginForm from "./LoginForm";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "black",
  },
  center: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
  },
  btnLink: {
    textDecoration: "underline",
    cursor: "pointer",
    float: "right",
  },
}));

export default function LoginPage() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <Grid container spacing={0} justifyContent="center">
            <Grid item xs={12} sm={6} lg={4} xl={4}>
              <div className={classes.center}>
                <img
                  //src="/img/logos/bangrip.png"
                  src="/img/logos/betstamp-word-white.svg"
                  height="117"
                  alt="Props - Betstamp"
                  style={{ textAlign: "center" }}
                ></img>
                <LoginForm />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
