import React from "react";
import styled from "styled-components";

// components
import { Row, Col } from "components/generic/Layout";
import { IconButton } from "components/AuthButton";

const InputRow = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  width: 100%;
  background-color: var(--color-fg);
  border-radius: 32px;
  align-items: center;
  transition: all var(--std-transition);
  border: ${(props) => {
    if (props.errorText) {
      return "1px solid var(--color-danger)";
    } else {
      return "1px solid var(--color-text-light)";
    }
  }};
  &:hover {
    box-shadow: 0 2px 8px var(--color-shadow);
  }
  &:focus-within {
    box-shadow: 0 2px 4px var(--color-shadow);
  }
`;

const StyledInput = styled.input`
  flex: 1;
  outline: none;
  padding: var(--space-sm);
  margin: 0;
  border: none;
  font-size: var(--text-base-size);
  font-family: "Noto Sans", sans-serif;
  background: transparent;
  color: var(--color-white);
  transition: all var(--std-transition);
`;

const StyledTextarea = styled.textarea`
  flex: 1;
  outline: none;
  padding: var(--space-sm);
  margin: 0;
  border: none;
  font-size: var(--text-base-size);
  font-family: "Noto Sans", sans-serif;
  background: transparent;
  transition: all var(--std-transition);
`;

export function AuthTextInput(props) {
  const {
    label,
    leftIcon,
    rightIcon,
    rightIconColor,
    rightIconSize,
    rightIconOnPress,
    errorText,
  } = props;

  const LeftIconComp = leftIcon || Dummy;
  const RightIconComp = rightIcon || Dummy;

  const InputComp = props.multiline ? StyledTextarea : StyledInput;

  return (
    <Col
      style={{
        flex: 0,
        width: "100%",
        margin: "var(--space-xs) 0",
        ...props.containerStyle,
      }}
    >
      {label && (
        <Row
          style={{
            width: "100%",
            justifyContent: "space-between",
            padding: "0 var(--space-sm)",
            alignItems: "flex-end",
          }}
        >
          <label>{label}</label>
          <small style={{ color: "var(--color-danger)" }}>{errorText}</small>
        </Row>
      )}
      <InputRow errorText={errorText}>
        <LeftIconComp
          size={24}
          color={errorText ? "var(--color-danger)" : "var(--color-white)"}
          style={{ flex: "0 0 24", paddingLeft: "var(--space-sm)" }}
          tabIndex={-1}
        />

        <InputComp
          placeholder={props.placeholder}
          maxLength={props.maxLength}
          autoFocus={props.autoFocus}
          value={props.value}
          defaultValue={props.defaultValue}
          type={props.type}
          onChange={(input) => props.onChangeText(input.target.value, input)}
          onBlur={props.onEndEditing}
          name={props.name}
          id={props.id}
          accept={props.accept}
          rows="5"
        />

        {rightIconOnPress ? (
          <IconButton
            onPress={rightIconOnPress}
            colorTheme="text"
            iconName={RightIconComp}
            iconSize={rightIconSize || 24}
            color={
              rightIconColor ||
              (errorText ? "var(--color-danger)" : "var(--color-white)")
            }
            btnStyle={{ padding: "0 10px" }}
            tabIndex={-1}
          />
        ) : (
          <>
            {rightIcon && (
              <RightIconComp
                size={rightIconSize || 24}
                color={
                  rightIconColor ||
                  (errorText ? "var(--color-danger)" : "var(--color-white)")
                }
                style={{ flex: "0 0 0px", paddingRight: "var(--space-sm)" }}
              />
            )}
          </>
        )}
      </InputRow>
    </Col>
  );
}

function Dummy(props) {
  return <div {...props} />;
}
