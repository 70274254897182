import CircularProgress from "@mui/material/CircularProgress";

export default function FetchingPlayerProps({ styles }) {
  return (
    <div className={styles.fetchWrap}>
      <div className={styles.fetchSpanText}>
        <div className={styles.fetchWrapChild}>Loading Prop Lines, Emperor</div>
      </div>
      <div className={styles.fetchSpanText}>
        <div className={styles.fetchWrapChildSpinner}>
          <CircularProgress />
        </div>
      </div>
    </div>
  );
}
