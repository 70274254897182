import React, { useContext } from "react";
import { Context } from "../../Store";
import { makeStyles } from "@mui/styles";
import Checkbox from "@mui/material/Checkbox";

import { setSkipcache } from "../../actions/select";

const useStyles = makeStyles((theme) => ({
  formSelect: {
    margin: 2,
    fontWeight: "bold",
    fontSize: 12,
  },
  formControlTouchUp: {
    backgroundColor: "#FFF",
  },
}));

export default function SkipcacheSelect() {
  const [{ skipcache }, dispatch] = useContext(Context);
  const styles = useStyles();
  return (
    <div className={styles.formSelect}>
      <div style={{ color: "#FFF", width: "80px" }}>Skip Cache</div>
      <Checkbox
        color="secondary"
        checked={skipcache}
        onChange={({ target: { value } }) => dispatch(setSkipcache(!skipcache))}
        className={styles.formControlTouchUp}
      />
    </div>
  );
}
