import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import { Col } from "components/generic/Layout";
import {
  IoArrowForwardCircle,
  IoEyeOffOutline,
  IoEyeOutline,
  IoLockClosedOutline,
  IoMailOutline,
} from "react-icons/io5";
import { AuthTextInput } from "components/AuthTextInput";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { Context } from "../../Store";
import {
  login,
  setAuth,
  //extractUserFromToken,
  //keepUserTokenRefreshed,
} from "../../actions/auth";

import Button from "@mui/material/Button";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
  textField: {
    width: "100%",
  },
}));

const Wrapper = styled.div`
  background-color: var(--color-primary);
  color: white;
  flex: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: center;
`;

export default function LoginForm() {
  const classes = useStyles();
  const [context, dispatch] = useContext(Context);
  const { isLoading: isSubmitting } = context;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const token = window.localStorage.getItem("access");

  const _onSubmit = (ev) => {
    if (ev) ev.preventDefault();
    login(
      { username: username, password: password },
      (result) => {
        const { token, user } = result;
        dispatch(
          setAuth({
            token: token,
            isAuthenticated: true,
            user,
          }),
        );
      },
      (e) => {
        setError(e.message);
      },
      [context, dispatch],
    );
  };

  const history = useHistory();

  useEffect(() => {
    if (token) {
      history.replace("/");
    }
  }, [token, history]);

  return (
    <Wrapper>
      <Col
        style={{
          maxWidth: "520px",
          width: "100%",
          margin: "0 auto",
          justifyContent: "flex-start",
          padding: "0 var(--space-xs)",
          display: "flex",
        }}
      >
        <form onSubmit={_onSubmit} className={classes.textField}>
          <AuthTextInput
            leftIcon={IoMailOutline}
            placeholder={"Username"}
            textContentType={"username"}
            value={username}
            autoFocus={true}
            type="username"
            onChangeText={(text) => setUsername(text)}
          />
          <AuthTextInput
            leftIcon={IoLockClosedOutline}
            rightIcon={showPassword ? IoEyeOutline : IoEyeOffOutline}
            rightIconOnPress={() => {
              setShowPassword(!showPassword);
            }}
            placeholder={"Password"}
            value={password}
            type={showPassword ? "text" : "password"}
            onChangeText={(text) => setPassword(text)}
          />
          <Button
            fullWidth={true}
            variant="outlined"
            disabled={isSubmitting}
            type={"submit"}
            style={{
              backgroundColor: "white",
              color: "var(--color-primary)",
              borderColor: "var(--color-primary)",
              borderStyle: "solid",
              borderWidth: 2,
              borderRadius: 32,
              marginBottom: 15,
              padding: "var(--space-xs) var(--space-sm)",
              fontSize: "var(--text-base-size)",
              fontFamily: "Noto Sans",
            }}
            endIcon={<IoArrowForwardCircle />}
            size={"large"}
          >
            Submit
          </Button>
        </form>
      </Col>
      <Snackbar open={error !== ""} autoHideDuration={6000}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </Wrapper>
  );
}
