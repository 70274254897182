import React, { useContext } from "react";
import { Context } from "../../Store";
import { makeStyles } from "@mui/styles";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { setTimedelta } from "../../actions/select";

const useStyles = makeStyles((theme) => ({
  timedeltaSelect: {
    backgroundColor: "#FFF",
    padding: "4px 6px !important",
  },
}));

export default function TimedeltaSelect() {
  const [{ timedelta }, dispatch] = useContext(Context);
  const styles = useStyles();
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "128px" }}>
      <label
        htmlFor="timedelta-select"
        style={{ color: "white", fontSize: "80%" }}
      >
        Game Start In
      </label>
      <Select
        labelId="timedelta-select"
        value={timedelta}
        className={styles.timedeltaSelect}
        classes={{ select: styles.timedeltaSelect }}
        onChange={({ target: { value } }) => dispatch(setTimedelta(value))}
      >
        <MenuItem value="1">1 Hour</MenuItem>
        <MenuItem value="4">4 Hours</MenuItem>
        <MenuItem value="24">1 Day</MenuItem>
        <MenuItem value="168">7 Days</MenuItem>
        <MenuItem value="336">14 Days</MenuItem>
      </Select>
    </div>
  );
  //return (
  //  <FormControl variant="outlined" className={styles.timedeltaSelect}>
  //    <div
  //      style={{
  //        color: "#FFF",
  //        width: "192px",
  //        fontSize: 12,
  //        fontWeight: "bold",
  //      }}
  //    >
  //      Game Start Within Hours
  //    </div>
  //    <Input
  //      value={timedelta}
  //      className={styles.formControlTouchUp}
  //      onChange={({ target: { value } }) => dispatch(setTimedelta(value))}
  //    ></Input>
  //  </FormControl>
  //);
}
